/*Custom theme colors*/

:root {
  --light-grey: #f0f0f0;
  --off-white: #f7f7f7;
  --soft-off-white: #ececec; /* Slightly less bright than off-white */
  --softer-off-white: #cccccc; /* Even softer than soft-off-white */
  --dark-grey: #4a4a4a;
  --turquoise: #008b8b;
  --pnw-blue: #005f73;
}

a {
  color: var(--pnw-blue); /* Set a custom color for links */
  text-decoration: none; /* Remove the underline */
  font-weight: 600; /* Make the text slightly bolder */
  transition: color 0.3s ease; /* Smooth transition effect */
}

a:hover {
  color: var(--turquoise); /* Change color on hover */
  text-decoration: underline; /* Add underline on hover */
}

body {
  background-color: var(--off-white); /* Example color */
  color: var(--dark-grey); /* This sets the default font color */
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 18px; /* Adjust this value as needed */
}

.container-header {
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; 
  padding-top: 20px;   
  top: 0;
  z-index: 1; 
  background-color: var(--off-white); /* Ensure the header has a solid background */
  box-shadow: 0 1px 0px rgba(0, 0, 0, 0.1); /* Optional: add a shadow for better visibility */
  padding: 10px; /* Adjust the padding as needed */
}

.body-container {
  width: 90%;
  flex-wrap: wrap;
  gap: 20px; /* Space between images */
  margin: 0 auto; /* Center the .collections-grid within its parent container */
  margin-top: 25px;
  margin-bottom: 25px;
}

/*Begin footer styling*/

.footer-container {
  width: 90%;
  margin: 0 auto; /* Centers the footer */
  padding: 20px 0;
  text-align: center; /* Centers the text */
}

.footer-divider {
  border: none;
  height: 1px;
  background-color: #d3d3d3; /* Light grey color */
  margin-bottom: 20px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-icons {
  margin-top: 10px;
}

.footer-icons a {
  color: #4a4a4a; /* Dark grey color */
  margin: 0 10px;
  font-size: 24px;
}

.footer-icons a:hover {
  color: #005f73; /* PNW blue color */
}
/*End footer styling*/

/*Begin: Menu functionality*/

.menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.menu-toggle {
  display: none; /* Hide by default on larger screens */
  cursor: pointer;
  color: var(--dark-grey); /* Text/icon color */
  padding: 10px;
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 1100; /* Ensure it stays above other elements */
  width: 50px; /* Set minimum width */
  height: 50px; /* Set minimum height */
  text-align: center; /* Center the icon inside */
  line-height: 30px; /* Vertically center the icon */
  font-size: 35px; /* Increase font size for better visibility */
  box-sizing: border-box; /* Ensure padding is included in the width/height */
  border-radius: 5px; /* Optional: Add some rounding to the button */
}

.menu-item {
  position: relative;
  margin-left: 20px;
}

.menu-item a {
  text-decoration: none;
  font-size: 22px; /* Adjust as needed */
  font-weight: 100; /* Regular weight */
  color: var(--dark-grey);
  padding: 10px;
  display: block;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  background-color: var(--off-white);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  min-width: 200px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdown-menu li {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.dropdown-menu li a {
  color: var(--dark-grey);
}

.menu-item.dropdown:hover .dropdown-menu {
  display: block; /* Show dropdown on hover */
}

.menu-item a:hover,
.dropdown-menu li a:hover {
  background-color: var(--dark-grey);
  color: var(--off-white);
}

/*End: Menu functionality*/

/*Begin: style individual divs*/

.logo {
  padding-left: 25px;
  text-align: center;
  color: grey;
}

.main-nav {
  padding-right: 25px;
}

.body-spacing {
  margin-top: 25px;
  margin-bottom: 25px;
}

/*End: style individual divs*/

/*Begin Carousel CSS*/
.carousel-container {
  position: relative;
  height: 90vh;
  margin: auto;
  padding-top: 20px;
}

.carousel-slide {
  position: relative;
  height: 90%; /* Adjust the height to accommodate the text */
}

.carousel-item {
  position: absolute;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.carousel-description {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.carousel-item.active {
  opacity: 1;
}

.carousel-item img {
  width: 100%;
  object-fit: contain; /* Ensures the image covers the container */
  object-position: center; /* Centers the image within the container */
  display: block;
}

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 100;
  font-size: 18px;
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

/*End Carousel CSS*/

/*Begin Collections Grid CSS*/

.collections-grid {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns of equal width */
  gap: 15px; 
  justify-items: center; /* Horizontally center items within each cell */
  align-items: center; /* Vertically center items within each cell */
  position: relative;
  padding-top: 20px;  /* Padding at the top */
  padding-bottom: 20px;  /* Padding at the bottom */
  margin: 0 auto; /* Center the .collections-grid within its parent container */
}

.collections-item {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-items: center; /* Horizontally center items within each cell */
  align-items: center; /* Vertically center items within each cell */
}

.collections-item img {
  width: 100%; /* Make the image take up full width of its container */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensure image covers the container without distortion */
  object-position: center; /* Center the image within its container */
  display: block; /* Ensure no extra space below the image */
}

/*End Collections Grid CSS*/

/*Begin display image assets CSS*/

.images-container {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Distribute space between images */
  gap: 20px; /* Space between images */
  margin: 0 auto; /* Center the .collections-grid within its parent container */
}

.image-item {
  flex: 1 1 calc(25% - 20px); /* Adjust width for 3 items per row and account for gap */
  box-sizing: border-box; /* Ensure padding and border are included in the width */
  max-width: 800px; /* Set a maximum width for the images */
  text-align: center;
  margin-bottom: 20px; /* Add space between the image and the text box */
}

.image-item img {
  width: 100%; /* Make the image take up full width of its container */
  height: auto; /* Maintain aspect ratio */
  max-height: 500px; /* Limit the height to prevent overflow */
  object-fit: contain; /* Ensure image scales correctly without distortion */
  display: block; /* Removes any extra space below the image */
  margin-bottom: 10px; /* Space between the image and the text box */
}

.image-metadata {
  padding: 10px;
  background-color: var(--light-grey); /* Optional: Background color for the text box */
  color: var(--dark-grey); /* Ensure text is readable */
  text-align: left;
}

/*End display image assets CSS*/

/*Begin pagination controls styling */
.pagination-controls {
  text-align: center;
  margin-top: 20px;
}

.pagination-controls button {
  padding: 10px 20px;
  margin: 0 10px; /* Increase horizontal margin for more space between buttons */
  font-size: 16px;
  background-color: var(--dark-grey);
  color: var(--off-white);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.pagination-controls span {
  margin: 0 20px; /* Add more space between the buttons and the page number text */
  font-size: 16px;
}

/*End pagination controls styling*/

/*Begin Lightbox CSS*/

.lightbox-background {
  cursor: grab;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.lightbox-background:active {
  cursor: grabbing;
}

.lightbox-content {
  position: relative;
  display: flex;
  flex-direction: row; /* Side by side layout */
  justify-content: center;
  height: auto;
  width: 90%;
}

.lightbox-image-container {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.lightbox-image {
  width: 100%;
  max-height: 90vh;
  height: auto;
  object-fit: contain; /* Ensures the image scales correctly */
}

.caption {
  position: relative;
  color: var(--softer-off-white);
  text-align: left;
  background: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  width: 25%;
  padding-left: 25px;
  padding-right: 10px;
}

/* Close Button Styling */
.close {
  position: relative;
  top: 0px;
  right: -20px;
  color: var(--off-white);
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
  z-index: 1001;
}

.close:hover {
  color: var(--pnw-blue);
}
/*End Lightbox CSS*/

/* Media query for screens smaller than 1100px (e.g., tablets and mobile) */
@media (max-width: 1100px) {

  .container-header {
    position: relative;
  }

  .container-body {
    width: 100vw; /* Full viewport width */
  }

  .logo {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .main-nav {
    display: none; /* Hide the menu by default on mobile */
    position: absolute;
    top: 60px; /* Adjust based on your header height */
    left: 0;
    right: 0;
    background-color: rgb(215, 215, 215);
    z-index: 1000;
    flex-direction: column; /* Stack menu items vertically */
    align-items: center; /* Center the menu items horizontally */
  }

  .menu-toggle {
    display: block; /* Make sure the hamburger menu is visible */
  }

  .main-nav.menu-open {
    display: flex; /* Show menu when toggle is activated */
  }

  .menu {
    flex-direction: column; /* Stack menu items vertically */
    align-items: center; /* Center the menu items horizontally */
  }

  .menu-item {
    width: 100%;
    text-align: center; /* Center the text within each menu item */
  }

  .menu-item a {
    width: 100%;
    font-size: 20px; /* Slightly smaller for mobile */
    font-weight: 400; /* Semi-bold for better visibility on small screens */
    margin-top: 30px;
    margin-bottom: 30px;
  }

  /* Disable hover for dropdown on mobile */
  .menu-item.dropdown:hover .dropdown-menu {
    display: none;
  }

  .menu-item.dropdown .dropdown-menu {
    position: relative;
    background-color: #fffafa;
    box-shadow: none;
  }

  .menu-item.dropdown .dropdown-menu li {
    padding: 10px 20px;
  }

  .menu-item.dropdown .dropdown-menu li a {
    padding: 10px;
    display: block;
  }

  .carousel-container {
    max-width: 100%; /* Ensure full width on smaller screens */
    height: 300px; /* Reduce height for mobile */
  }

  .carousel-slide {
    height: 70%; /* Adjust the height for mobile */
  }

  .carousel-description {
    font-size: 14px; /* Smaller text for mobile */
    padding: 0px; /* Reduce padding */
  }

  .prev-button,
  .next-button {
    padding: 8px; /* Smaller buttons */
    font-size: 16px; /* Smaller font size for buttons */
  }

  .collections-grid {
    grid-template-columns: 1fr; /* Single column on small screens or fewer items */
  }

  .image-item {
    flex: 1 1 100%; /* Full width for each image */
  }

  .pagination-controls {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center the items */
    margin-top: 20px;
  }

  .pagination-controls button,
  .pagination-controls span {
    margin: 10px 0; /* Add vertical spacing between the elements */
    width: 200px; /* Make buttons take up full width */
    text-align: center; /* Center text inside buttons and span */
  }
}

/* Hide menu-toggle on larger screens */
@media (min-width: 1100px) {
  .menu-toggle {
    display: none;
  }
}

@media (min-width: 1600px) {
  .carousel-container {
    max-width: 1000px; /* Further limit the width on very large screens */
    max-height: 600px; /* Further limit the height */
  }
}

/* Zoho CRM */

/* Form Container Styling */
#crmWebToEntityForm.zcwf_lblLeft {
  width: 100%; /* Take full width of the parent container */
  padding: 25px;
  box-sizing: border-box;
  background-color: transparent; /* Make the background transparent */
  color: var(--dark-grey); /* Match your text color */
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; /* Match your font family */
  text-align: left; /* Align text to the left */
  margin-left: 0; /* Ensure it's aligned to the left */
}

.zcwf_lblLeft .zcwf_title {
  font-weight: bold;
  font-size: 24px; /* Adjust font size if needed */
  color: var(--pnw-blue); /* Custom title color */
  margin-bottom: 20px; /* Add some space below the title */
}

.zcwf_lblLeft .zcwf_col_fld input[type=text], 
.zcwf_lblLeft .zcwf_col_fld input[type=password], 
.zcwf_lblLeft .zcwf_col_fld input[type=email], /* Specifically target email input */
.zcwf_lblLeft .zcwf_col_fld textarea {
  width: 100%; /* Full width for inputs */
  border: 1px solid var(--softer-off-white); /* Custom border color */
  padding: 12px; /* Increase padding for better appearance */
  font-size: 16px; /* Adjust font size if needed */
  color: var(--dark-grey); /* Match your text color */
  background-color: var(--soft-off-white); /* Match your input background color */
  margin-bottom: 15px; /* Space between form elements */
  border-radius: 4px; /* Optional: Add border radius for smoother corners */
}

.zcwf_lblLeft .zcwf_button {
  font-size: 14px;
  color: var(--off-white); /* Match your button text color */
  background-color: var(--pnw-blue); /* Match your button background color */
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition effect */
  margin-top: 10px; /* Space above the button */
}

.zcwf_lblLeft .zcwf_button:hover {
  background-color: var(--turquoise); /* Button hover color */
}

.zcwf_lblLeft .zcwf_privacy_txt {
  font-size: 12px;
  color: var(--dark-grey); /* Match your text color */
}

figure {
  margin: 0; /* Remove default margins */
  padding: 0;
  text-align: center; /* Center the caption */
}

figure img {
  width: 100%; /* Make the image responsive */
  max-width: 600px; /* Constrain the maximum width */
  height: auto; /* Maintain the aspect ratio */
  display: block; /* Remove any spacing below the image */
  margin: 0 auto; /* Center the image */
  padding-top: 25px;
  padding-bottom: 10px;
}

figcaption {
  font-size: 14px; /* Adjust font size for mobile devices */
  color: #555; /* Optional: Set a softer color for the caption text */
  padding: 5px 10px; /* Add some padding for spacing */
}